@font-face {
  font-family: "Judson";
  font-weight: 400;
  src: url("/fonts/Judson/Judson-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Judson";
  src: url("/fonts/Judson/Judson-Bold.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  font-weight: 400;
  src: url("/fonts/Lato/Lato-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("/fonts/Lato/Lato-Bold.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
}

/*NEW FONTS*/

@font-face {
  font-family: "Nunito";
  font-weight: 500;
  src: url("/fonts/Nunito/NunitoSans_Medium.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Nunito";
  src: url("/fonts/Nunito/NunitoSans_SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Nunito";
  src: url("/fonts/Nunito/NunitoSans_Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Nunito";
  src: url("/fonts/Nunito/NunitoSans_ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: "Koh";
  font-weight: 400;
  src: url("/fonts/Koh/KohSantepheap-Regular.ttf") format("truetype");
  font-display: swap;
}